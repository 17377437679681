import { CoaEntity, EMPTY_COA_ENTITY } from "domain/entity/Company/CoaEntity";
import { CreditNoteDetailEntity, EMPTY_CREDIT_NOTE_DETAIL_ENTITY } from "domain/entity/CreditNote/CreditNoteDetailEntity";
import { CreditNoteHeaderEntity } from "domain/entity/CreditNote/CreditNoteHeaderEntity";
import { DEFAULT_COUNTRY } from "domain/repository/apiUtil";
import { CreditNoteConstant, creditNoteDtlRequiredFieldList } from "presentation/constant/CreditNote/CreditNoteConstant";
import { uomDropdownOption, vatPaymentReferenceDateDropdownOption } from "presentation/constant/DropDownOptions/StaticDropdownOptions";
import { useCreditNoteDetailVM } from "presentation/hook/CreditNote/useCreditNoteDetailVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DatePickerComponent from "presentation/view/components/DatePickerComponent";
import { NbisCommonField } from "presentation/view/components/NbisCommonField";
import NumberInputComponent from "presentation/view/components/NumberInputComponent";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FieldType, HPHButton, HPHCheckbox, IFieldValue, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";

export const CreditNoteDetailFormPanel = () => {
    const [creditNoteDetailState] = useCreditNoteDetailTracked();
    const creditNoteDetailVM = useCreditNoteDetailVM();
    const messageBarVM = useMessageBarVM();
    const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Detail;
    const [isLoading, setIsLoading] = useState(false);
    // const [initScreen, setInitScreen] = useState(true);
    const { isDetailAddClick, currentCreditNoteDtl, isAdd, isEditable, isRead, isSaveClicked, allFormState, editingHeader, isByManual, isByInvoice } = creditNoteDetailState.creditNoteDetailState;
    const currentCreditNoteHdr = isRead ? creditNoteDetailState.currentCreditNoteHdr : editingHeader;
    const { creditNoteDetails, selectedcreditNoteDetailRows } = creditNoteDetailState;
    const [anaInfoState] = useANAInfoTracked();
    const countryCode = DEFAULT_COUNTRY ?? "NLD";//= "PAK";
    const companyCode = anaInfoState.defaultOperatingCompany ?? "";//= "2691";
    const isReadOnly = (!!currentCreditNoteDtl.invoiceNo);

    const handleCancelClick = useCallback(() => {
        creditNoteDetailVM.onCancelClick();
    }, [creditNoteDetailVM])

    const updateInterCompanyByBillToAndCustomer = useCallback(async (isShowMsg: boolean) => {
        if (currentCreditNoteHdr.billToCompany && currentCreditNoteHdr.customerCode
            && (isAdd || isEditable) && !currentCreditNoteDtl.invoiceNo) {
            const interCompanyCodes: string[] = [];
            const companyCustomerList = await creditNoteDetailVM.getCustomersByCompany(currentCreditNoteDtl.opsDate, currentCreditNoteHdr.customerCode, currentCreditNoteHdr.billToCompany);
            if (companyCustomerList != null && companyCustomerList.length > 0) {
                for (let i = 0; i < companyCustomerList.length; i++) {
                    const companyCustomer = companyCustomerList[i];
                    if ((!companyCustomer.chargeType || companyCustomer.chargeType === "CR") && companyCustomer.intercompanyCode) {
                        interCompanyCodes.push(companyCustomer.intercompanyCode);
                    }
                }
            }
            if (interCompanyCodes && interCompanyCodes.length === 1) {
                creditNoteDetailVM.setInterCompanyCode(interCompanyCodes[0]);
            } else if (interCompanyCodes && interCompanyCodes.length > 1 && isShowMsg) {
                const allInterCompanyCode: string = getInterCompanyCodeString(interCompanyCodes);
                messageBarVM.showError(allInterCompanyCode + " (Inter-company Code) meet bill-to company " + currentCreditNoteHdr.billToCompany + " and customer code " + currentCreditNoteHdr.customerCode + ".\n Please select one of them.");
            }
        }
    }, [creditNoteDetailVM, currentCreditNoteHdr.billToCompany, currentCreditNoteHdr.customerCode, currentCreditNoteDtl.invoiceNo, currentCreditNoteDtl.opsDate, isAdd, isEditable, messageBarVM])

    const updateInterCompanyCodeByCoas = useCallback((coas: CoaEntity[], isShowMsg: boolean) => {
        if (!currentCreditNoteHdr.billToCompany || !currentCreditNoteHdr.customerCode) {
            const interCompanyCodes: string[] = [];
            coas?.forEach((coa) => {
                if (coa.intercompanyCode && !interCompanyCodes.includes(coa.intercompanyCode)) {
                    interCompanyCodes.push(coa.intercompanyCode);
                }
            });
            if (interCompanyCodes && interCompanyCodes.length === 1) {
                creditNoteDetailVM.setInterCompanyCode(interCompanyCodes[0]);
            } else if (interCompanyCodes && interCompanyCodes.length > 1 && isShowMsg) {
                const allInterCompanyCode: string = getInterCompanyCodeString(interCompanyCodes);
                messageBarVM.showError(allInterCompanyCode + " (Inter-company Code)  meet the conditions.\n Please select one of them.");
            }
        }
    }, [creditNoteDetailVM, currentCreditNoteHdr.billToCompany, currentCreditNoteHdr.customerCode, messageBarVM])


    const updateCoaOrValidCoa = useCallback(async (isUpdate: boolean, isValid: boolean, isShowMsg: boolean) => {
        if (currentCreditNoteDtl.tariffType && currentCreditNoteDtl.tariffCode) {
            let isCoaMatched = true;
            let noRecordFound = false;
            const tarCoa = await creditNoteDetailVM.getActiveTarffCoa(currentCreditNoteDtl.tariffType, currentCreditNoteDtl.tariffCode);
            const coas = await creditNoteDetailVM.getCoa(tarCoa, countryCode);
            if (isUpdate) {
                if (tarCoa) {
                    if (coas && coas.length >= 1) {
                        creditNoteDetailVM.coaInfoUpdate(coas[0]);
                        updateInterCompanyCodeByCoas(coas, isShowMsg);
                    } else {
                        if (isShowMsg) {
                            messageBarVM.showError("No such COA record found.");
                        }
                        noRecordFound = true;
                    }
                } else {
                    if (isShowMsg) {
                        messageBarVM.showError("No such Tariff COA Mapping record defined.");
                    }
                    noRecordFound = true;
                }
                if (noRecordFound) {
                    creditNoteDetailVM.coaInfoUpdate(EMPTY_COA_ENTITY);
                }
                updateInterCompanyByBillToAndCustomer(isShowMsg);
                // return true;
            } else if (isValid) {
                if (tarCoa) {
                    if (coas && coas.length >= 1) {
                        if (currentCreditNoteDtl.revenueAccountCode !== coas[0].revenueAccountCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.costCenterCode !== coas[0].costCenterCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.productServiceCode !== coas[0].productServiceCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.salesChannelCode !== coas[0].salesChannelCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.projectCode !== coas[0].projectCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.spareCode !== coas[0].spareCode) {
                            isCoaMatched = false;
                        }
                    } else {
                        isCoaMatched = false;
                    }
                } else {
                    isCoaMatched = false;
                }
                if (!isCoaMatched && isShowMsg) {
                    messageBarVM.showError("The message of COA inputted not match tariff.");
                }
                return isCoaMatched;
            }
            // return true;
        }
        return true;
    }, [countryCode, creditNoteDetailVM, currentCreditNoteDtl.costCenterCode, currentCreditNoteDtl.productServiceCode, currentCreditNoteDtl.projectCode, currentCreditNoteDtl.revenueAccountCode, currentCreditNoteDtl.salesChannelCode, currentCreditNoteDtl.spareCode, currentCreditNoteDtl.tariffCode, currentCreditNoteDtl.tariffType, messageBarVM, updateInterCompanyByBillToAndCustomer, updateInterCompanyCodeByCoas]);


    const getInterCompanyCodeString = (interCompanyCodes: string[]) => {
        let allInterCompanyCode: string = "";
        if (interCompanyCodes && interCompanyCodes.length > 0) {
            for (let i = 0; i < interCompanyCodes.length; i++) {
                const interCompanyCode: string = interCompanyCodes[i];
                if (i === 0) {
                    allInterCompanyCode = interCompanyCode;
                } else if (i !== 0 && i % 5 !== 0) {
                    allInterCompanyCode = allInterCompanyCode + "," + interCompanyCode;
                } else if (i !== 0 && i % 5 === 0) {
                    allInterCompanyCode = allInterCompanyCode + ",\n" + interCompanyCode;
                }
            }
        }
        return allInterCompanyCode;
    }

    const updateInterCompanyCodeByTaxCoas = useCallback((coas: CoaEntity[], isShowMsg: boolean) => {

        const interCompanyCodes: string[] = [];
        coas?.forEach((coa) => {
            if (coa.intercompanyCode && !interCompanyCodes.includes(coa.intercompanyCode)) {
                interCompanyCodes.push(coa.intercompanyCode);
            }
        });
        if (interCompanyCodes && interCompanyCodes.length === 1) {
            creditNoteDetailVM.setInterCompanyCode(interCompanyCodes[0]);
        } else if (interCompanyCodes && interCompanyCodes.length > 1 && isShowMsg) {
            const allInterCompanyCode: string = getInterCompanyCodeString(interCompanyCodes);
            messageBarVM.showError(allInterCompanyCode + " (Tax inter-company Code)  meet the conditions.\n Please select one of them.");
        }
    }, [creditNoteDetailVM, messageBarVM])

    const updateTaxCoaOrValidTaxCoa = useCallback(async (isUpdate: boolean, isValid: boolean, isShowMsg: boolean, isForTable: boolean) => {
        if (currentCreditNoteDtl.vatTariffType && currentCreditNoteDtl.vatTariffCode) {
            let isCoaMatched = true;
            let noRecordFound = false;
            const tarCoa = await creditNoteDetailVM.getActiveTarffCoa(currentCreditNoteDtl.vatTariffType, currentCreditNoteDtl.vatTariffCode);
            const coas = await creditNoteDetailVM.getCoa(tarCoa, countryCode);
            if (isUpdate) {
                if (tarCoa) {
                    if (coas && coas.length >= 1) {
                        creditNoteDetailVM.vatCoaInfoUpdate(coas[0]);
                        updateInterCompanyCodeByTaxCoas(coas, isShowMsg);
                    } else {
                        if (isShowMsg) {
                            messageBarVM.showError("No such COA record found.");
                        }
                        noRecordFound = true;
                    }
                } else {
                    if (isShowMsg) {
                        messageBarVM.showError("No such Tariff COA Mapping record defined.");
                    }
                    noRecordFound = true;
                }
                if (noRecordFound) {
                    creditNoteDetailVM.coaInfoUpdate(EMPTY_COA_ENTITY);
                }
                // return true;
            } else if (isValid) {
                if (tarCoa) {
                    if (coas && coas.length >= 1) {
                        if (currentCreditNoteDtl.vatRevenueACCode !== coas[0].revenueAccountCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.vatCostCenterCode !== coas[0].costCenterCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.vatProductServiceCode !== coas[0].productServiceCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.vatSalesChannelCode !== coas[0].salesChannelCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.vatProjectCode !== coas[0].projectCode) {
                            isCoaMatched = false;
                        }
                        if (currentCreditNoteDtl.vatSpareCode !== coas[0].spareCode) {
                            isCoaMatched = false;
                        }
                    } else {
                        isCoaMatched = false;
                    }
                } else {
                    isCoaMatched = false;
                }
                if (!isCoaMatched && isShowMsg) {
                    messageBarVM.showError("The message of COA inputted not match tariff.");
                }
                return isCoaMatched;
            }
            // return true;
        }
        return true;
    }, [countryCode, creditNoteDetailVM, currentCreditNoteDtl.vatCostCenterCode, currentCreditNoteDtl.vatProductServiceCode, currentCreditNoteDtl.vatProjectCode, currentCreditNoteDtl.vatRevenueACCode, currentCreditNoteDtl.vatSalesChannelCode, currentCreditNoteDtl.vatSpareCode, currentCreditNoteDtl.vatTariffCode, currentCreditNoteDtl.vatTariffType, messageBarVM, updateInterCompanyCodeByTaxCoas])

    const calculateVatAmt = useCallback((dtl: CreditNoteDetailEntity) => {
        let amount = 0;
        if (dtl.adjPercentage && dtl.creditAmount && dtl.docAdjType === "VAT") {
            amount = dtl.creditAmount * dtl.adjPercentage * 0.01;
        }
        return amount;
    }, []);
    const validateForRebateLine = useCallback((vatList: number[], currentCreditNoteDtl: CreditNoteDetailEntity) => {
        let isVatFail = false;
        let rebateTotalAmount = 0;
        let rebateTotalQty = 0;
        let creditTotalAmount = 0;
        let creditTotalQty = 0;
        if (creditNoteDetails && creditNoteDetails.length > 0) {
            let selectedCreEntity = null;
            if (selectedcreditNoteDetailRows && selectedcreditNoteDetailRows.length > 0) {
                selectedCreEntity = selectedcreditNoteDetailRows[0];
            }
            for (const dtl of creditNoteDetails) {
                if (!(selectedCreEntity && selectedCreEntity.id === dtl.id && !isAdd)) {
                    let adjPercentage = 0;
                    if (dtl.adjPercentage) {
                        adjPercentage = dtl.adjPercentage;
                    }
                    if ((dtl.rebate || dtl.isDiscountItem) && dtl.creditAmount && dtl.qty && dtl.adjAmount) {
                        rebateTotalAmount += dtl.creditAmount;
                        if (dtl.isInputAmountInd && dtl.isInputAmountInd === "Y") {
                            rebateTotalQty += dtl.qty;
                        }
                        if (!dtl.isDiscountItem) {
                            rebateTotalAmount += calculateVatAmt(dtl);
                        } else {
                            rebateTotalAmount += dtl.adjAmount;
                        }
                        if (vatList && vatList.length > 0
                            && !vatList.includes(adjPercentage) && !dtl.isDiscountItem
                        ) {
                            isVatFail = true;
                            break;
                        }
                    } else {
                        if (dtl.creditAmount && dtl.qty) {
                            creditTotalAmount += dtl.creditAmount;
                            if (dtl.isInputAmountInd && "Y" !== dtl.isInputAmountInd) {
                                creditTotalQty += dtl.qty;
                            }
                            creditTotalAmount += calculateVatAmt(dtl);
                        }
                    }
                }
            }
        }

        let inputVatPercent = 0;
        if (isEditable || isAdd) {
            let curRebateCreditQty = 0;
            let curRebateCreditAmount = 0;
            let curRebateVatAmount = 0;
            if (currentCreditNoteDtl.creditAmount) {
                curRebateCreditAmount = currentCreditNoteDtl.creditAmount;
            }
            if (currentCreditNoteDtl.creditChargingQty) {
                curRebateCreditQty = currentCreditNoteDtl.creditChargingQty;
            }
            if (currentCreditNoteDtl.vatPercent !== null) {
                inputVatPercent = currentCreditNoteDtl.vatPercent;
                curRebateVatAmount += Math.abs(curRebateCreditAmount * inputVatPercent * 0.01) * -1;
                if (vatList && vatList.length > 0 && !vatList.includes(inputVatPercent)) {
                    isVatFail = true;
                }
            }
            if (currentCreditNoteDtl.rebate) {
                rebateTotalAmount += (curRebateCreditAmount + curRebateVatAmount);
                if (curRebateCreditQty !== 0) {
                    rebateTotalQty += curRebateCreditQty;
                }
            } else {
                creditTotalAmount += (curRebateCreditAmount + Math.abs(curRebateVatAmount));
                if (curRebateCreditQty != null) {
                    creditTotalQty += curRebateCreditQty;
                }
            }
        }
        if (isVatFail) {
            messageBarVM.showError("Tax percentage is not the same.");
            return false;
        }
        let totalAmount = creditTotalAmount - Math.abs(rebateTotalAmount);
        if (creditNoteDetails && creditNoteDetails.length > 0 && totalAmount === 0) {
            messageBarVM.showError("Total amount must greater than zero.");
            return false;
        }
        if ((creditTotalQty === 0 && rebateTotalQty !== 0) ||
            (creditTotalQty !== 0 && rebateTotalQty !== 0 && Math.abs(rebateTotalQty) > creditTotalQty)) {
            messageBarVM.showError("Error: Rebate total qty should be less than total qty of credit tariff item.");
            return false;
        }
        if (creditTotalAmount < Math.abs(rebateTotalAmount)) {
            messageBarVM.showError("Error: Rebate total amount should be less than total amount of credit tariff item.");
            return false;
        }

        return true;
    }, [calculateVatAmt, creditNoteDetails, isAdd, isEditable, messageBarVM, selectedcreditNoteDetailRows]);
    const checkTarCodeOrCOA = useCallback((currentCreditNoteHdr: CreditNoteHeaderEntity, currentCreditNoteDtl: CreditNoteDetailEntity) => {
        let tarEmpty = true;
        let soaEmpty = true;
        let descEmpty = true;
        if (currentCreditNoteDtl.tariffType && currentCreditNoteDtl.tariffCode && currentCreditNoteHdr.billToCompany) {
            tarEmpty = false;
        }
        if (!tarEmpty || (currentCreditNoteDtl.dtlDesc1 && currentCreditNoteDtl.dtlDesc2)) {
            descEmpty = false;
        }
        if (currentCreditNoteDtl.revenueAccountCode
            && currentCreditNoteDtl.costCenterCode
            && currentCreditNoteDtl.productServiceCode
            && currentCreditNoteDtl.salesChannelCode
            && currentCreditNoteDtl.projectCode
            && currentCreditNoteDtl.spareCode
            && currentCreditNoteDtl.intercompanyCode
            && !descEmpty
        ) {
            soaEmpty = false;
        }
        if (!tarEmpty &&
            (!currentCreditNoteDtl.revenueAccountCode
                && !currentCreditNoteDtl.costCenterCode
                && !currentCreditNoteDtl.productServiceCode
                && !currentCreditNoteDtl.salesChannelCode
                && !currentCreditNoteDtl.projectCode
                && !currentCreditNoteDtl.spareCode
                && !currentCreditNoteDtl.intercompanyCode)
        ) {
            soaEmpty = false;
        }
        if (!soaEmpty && (!currentCreditNoteDtl.tariffType && !currentCreditNoteDtl.tariffCode)) {
            tarEmpty = false;
        }
        if ((tarEmpty || soaEmpty)) {
            messageBarVM.showError("[Tar Type,Tar Code,Bill To Company] Or\n[Detail Desc1,Detail Desc2,Inter Company Code, \nRevenue AC Code,Cost Center Code, \nProduct Service Code,Sales Channel Code, \nProject Code,Spare Code] is mandatory.");
            return false;
        }
        if (tarEmpty) {
            if (!currentCreditNoteDtl.dtlDesc1) {
                messageBarVM.showError("Detail Desc1 is mandatory.");
                return false;
            }
            if (!currentCreditNoteDtl.dtlDesc2) {
                messageBarVM.showError("Detail Desc2 is mandatory.");
                return false;
            }
        }
        return true;
    }, [messageBarVM]);

    const billToCpyCustomerCodeValidate = useCallback((billToCompanyRcd: string | null, customerCodeRcd: string | null) => {
        let isMatched = true;
        if (creditNoteDetails && creditNoteDetails.length > 0 && editingHeader) {
            // for (const dtl of creditNoteDetails) {
            if (editingHeader.billToCompany !== null && editingHeader.billToCompany !== billToCompanyRcd) {
                isMatched = false;
            }
            if (editingHeader.billToCompany === null && billToCompanyRcd !== null) {
                isMatched = false;
            }
            if (editingHeader.customerCode !== null && editingHeader.customerCode !== customerCodeRcd) {
                isMatched = false;
            }
            if (editingHeader.customerCode === null && customerCodeRcd !== null) {
                isMatched = false;
            }
            if (!isMatched) {
                messageBarVM.showError("Bill-to company " + billToCompanyRcd === null ? "[]" : billToCompanyRcd
                    + " and customer code " + customerCodeRcd === null ? "[]" : customerCodeRcd + " does not match with the others details");
                return false;
            }
            // }
        }
        return isMatched;
    }, [creditNoteDetails, messageBarVM, editingHeader]);
    const recordValidate = useCallback(() => {
        let billToCompanyRcd = currentCreditNoteHdr.billToCompany;
        let customerCodeRcd = currentCreditNoteHdr.customerCode;
        let isSelectedDiscount = false;
        let selectedCreEntity = EMPTY_CREDIT_NOTE_DETAIL_ENTITY;
        if (selectedcreditNoteDetailRows && selectedcreditNoteDetailRows.length > 0) {
            selectedCreEntity = selectedcreditNoteDetailRows[0];
            isSelectedDiscount = selectedCreEntity.isDiscountItem;
        }
        if (creditNoteDetails && ((creditNoteDetails.length > 1 && !isAdd)
            || (creditNoteDetails.length > 0 && isAdd))) {
            for (const dtl of creditNoteDetails) {
                let strInvoiceNo = null;
                if (dtl.invoiceNo) {
                    if (dtl.id === selectedCreEntity.id && !isAdd) {
                        strInvoiceNo = dtl.invoiceNo;
                    } else {
                        strInvoiceNo = dtl.invoiceNo;
                    }
                }
                if (strInvoiceNo) {
                    if (!billToCpyCustomerCodeValidate(billToCompanyRcd, customerCodeRcd)) {
                        return false;
                    }
                    break;
                }
            };
        }

        if (!currentCreditNoteHdr.currencyCode) {
            messageBarVM.showError('Currency is mandatory.');
            return false;
        }
        if (!currentCreditNoteHdr.customerCode) {
            messageBarVM.showError('Customer Code is mandatory.');
            return false;
        }
        if (!currentCreditNoteDtl.reasonCode) {
            messageBarVM.showError('Reason Code is mandatory.');
            return false;
        }
        if (currentCreditNoteDtl.invoiceNo && !currentCreditNoteDtl.oriAmount) {
            messageBarVM.showError('oriAmount is mandatory.');
            return false;
        }
        if (currentCreditNoteDtl.vatPercent === null) {
            messageBarVM.showError('Tax % is mandatory.');
            return false;
        }
        if (currentCreditNoteDtl.oriChargingQty && currentCreditNoteDtl.creditChargingQty
            && currentCreditNoteDtl.oriChargingQty < currentCreditNoteDtl.creditChargingQty) {
            messageBarVM.showError("Error: Credit Amount cannot greater than Original Amount.");
            return false;
        }
        if (!currentCreditNoteDtl.creditAmount) {
            messageBarVM.showError('Credit Amount is mandatory.');
            return false;
        } else {
            let discountCheck = false;
            if (selectedCreEntity.isDiscountItem) {
                discountCheck = true;
            }
            if (discountCheck) {
                if (currentCreditNoteDtl.creditAmount > 0) {
                    messageBarVM.showError('Error: Discount Credit Amount cannot be greater than 0.');
                    return false;
                }
            } else {
                if (currentCreditNoteDtl.docDtlId) {
                    if (currentCreditNoteDtl.creditAmount <= 0 && currentCreditNoteDtl.AdjDtlsIds.length <= 1) {
                        messageBarVM.showError('Error:Credit Amount should be greater than 0.');
                        return false;
                    }
                } else if (!currentCreditNoteDtl.docDtlId && !currentCreditNoteDtl.rebate) {
                    if (!currentCreditNoteDtl.rebate) {
                        if (currentCreditNoteDtl.creditAmount <= 0) {
                            messageBarVM.showError('Error:Credit Amount should be greater than 0.');
                            return false;
                        }
                    }
                }
            }
        }

        if (currentCreditNoteDtl.creditChargingQty) {
            if (currentCreditNoteDtl.creditChargingQty <= 0 && !currentCreditNoteDtl.rebate) {
                messageBarVM.showError("Error: Credit Charging Qty should be greater than 0.");
                return false;
            }
        }
        if (!isSelectedDiscount && currentCreditNoteDtl.oriAmount !== null && currentCreditNoteDtl.creditAmount !== null
            && currentCreditNoteDtl.oriAmount < currentCreditNoteDtl.creditAmount) {
            messageBarVM.showError("Error: Credit Amount cannot greater than Original Amount.");
            return false;
        }
        let vatList: number[] = [];
        if (currentCreditNoteDtl.rebate) {
            if (currentCreditNoteDtl.creditChargingQty !== null && currentCreditNoteDtl.creditChargingQty >= 0) {
                messageBarVM.showError("Error: Credit Charging Qty should be less than 0.");
                return false;
            }
            if (currentCreditNoteDtl.creditAmount !== null) {
                if (currentCreditNoteDtl.creditAmount >= 0) {
                    messageBarVM.showError("Error: Credit Amount should be less than 0.");
                    return false;
                }
            }
            if (!creditNoteDetails || creditNoteDetails.length < 1) {
                messageBarVM.showError("Please create a credit tariff item first.");
                return false;
            }
            if (creditNoteDetails && creditNoteDetails.length > 0) {
                for (const dtl of creditNoteDetails) {
                    if (dtl.adjPercentage !== null) {
                        if (!vatList.includes(dtl.adjPercentage) && (isAdd || (selectedCreEntity
                            && selectedCreEntity.id === dtl.id && !isAdd)) && !dtl.isDiscountItem && !dtl.rebate) {
                            vatList.push(dtl.adjPercentage);
                        }

                    }
                }
            }
        }
        if (!validateForRebateLine(vatList, currentCreditNoteDtl)) {
            return false;
        }

        if (!currentCreditNoteDtl.individualChargeQtyUom) {
            messageBarVM.showError('Charge UOM is mandatory.');
            return false;
        }

        if (!currentCreditNoteDtl.invoiceNo && !currentCreditNoteDtl.opsDate) {
            messageBarVM.showError('Ops Date is mandatory.');
            return false;
        }

        if (!checkTarCodeOrCOA(currentCreditNoteHdr, currentCreditNoteDtl)) {
            return false;
        }

        if (currentCreditNoteDtl.vatDays && currentCreditNoteDtl.vatDays < 0) {
            messageBarVM.showError('Transaction Type is mandatory.');
            return false;
        }
        let CHECK_TAX_PERCENTAGE_IND = false;
        let VALIDATE_INPUT_CREDIT_AMOUNT = true;
        if (VALIDATE_INPUT_CREDIT_AMOUNT) {

            // let creditQty = currentCreditNoteDtl.creditChargingQty?currentCreditNoteDtl.creditChargingQty:1;
            // let creditAmount = currentCreditNoteDtl.creditAmount;
            // const valAmountUp = creditAmount.dividedBy(creditQty).toDecimalPlaces(2, Decimal.ROUND_UP).toNumber();
            //VALIDATE_INPUT_CREDIT_AMOUNT 
        }

        let vatTarEmpty = true;
        let vatSoaEmpty = true;
        let vatDtlDesc = true;
        let vatOthers = true;

        if (!currentCreditNoteDtl.invoiceNo
            && (currentCreditNoteDtl.vatTariffType
                || currentCreditNoteDtl.vatTariffCode
                || currentCreditNoteDtl.vatRevenueACCode
                || currentCreditNoteDtl.vatCostCenterCode
                || currentCreditNoteDtl.vatPercent !== null
                || currentCreditNoteDtl.vatSalesChannelCode
                || currentCreditNoteDtl.vatProductServiceCode
                || currentCreditNoteDtl.vatSpareCode
                || currentCreditNoteDtl.vatDays
                || currentCreditNoteDtl.vatIntercompanyCode
                || currentCreditNoteDtl.vatDateType
                || currentCreditNoteDtl.vatArticleStatementCode
                || (currentCreditNoteDtl.vatDtlDesc1
                    || currentCreditNoteDtl.vatDtlDesc2)
                || currentCreditNoteDtl.taxCode)) {
            if (!CHECK_TAX_PERCENTAGE_IND) {
                if (!currentCreditNoteDtl.vatTariffType
                    && !currentCreditNoteDtl.vatTariffCode) {
                    vatTarEmpty = false;
                }
            }
            if (currentCreditNoteDtl.vatTariffType
                && currentCreditNoteDtl.vatTariffCode) {
                vatTarEmpty = false;
            }
            if (currentCreditNoteDtl.vatRevenueACCode
                && currentCreditNoteDtl.vatCostCenterCode
                && currentCreditNoteDtl.vatSalesChannelCode
                && currentCreditNoteDtl.vatProductServiceCode
                && currentCreditNoteDtl.vatSpareCode
                && currentCreditNoteDtl.vatIntercompanyCode) {
                vatSoaEmpty = false;
            }
            if (vatSoaEmpty || (!vatSoaEmpty &&
                (currentCreditNoteDtl.vatDtlDesc1
                    || currentCreditNoteDtl.vatDtlDesc2))) {
                vatDtlDesc = false;
            }
            if (!vatTarEmpty &&
                (!currentCreditNoteDtl.vatRevenueACCode
                    && !currentCreditNoteDtl.vatCostCenterCode
                    && !currentCreditNoteDtl.vatSalesChannelCode
                    && !currentCreditNoteDtl.vatProductServiceCode
                    && !currentCreditNoteDtl.vatSpareCode
                    && !currentCreditNoteDtl.vatIntercompanyCode)) {
                vatSoaEmpty = false;
            }
            if (!vatSoaEmpty &&
                !currentCreditNoteDtl.vatTariffType
                && !currentCreditNoteDtl.vatTariffCode) {
                vatTarEmpty = false;
            }
            if (currentCreditNoteDtl.vatDays &&
                currentCreditNoteDtl.vatDateType
                && currentCreditNoteDtl.vatArticleStatementCode
                && currentCreditNoteDtl.taxCode
            ) {
                if (!CHECK_TAX_PERCENTAGE_IND) {
                    vatOthers = false;
                } else {
                    if (currentCreditNoteDtl.vatPercent !== null) {
                        vatOthers = false;
                    }
                }
            }
            if (vatTarEmpty || vatSoaEmpty) {
                messageBarVM.showError("Tax Tariff Type , Tax Tariff Code Or\n Tax Revenue A/C Code,Tax Cost Center Code\n Tax Sales Channel Code,Tax Product Service Code,Tax Project Code,Tax Spare Code,Tax Inter-company Code \n please input them together.");
                return false;
            }
            if (vatOthers) {
                messageBarVM.showError("TAX % , Tax Payment Term , Tax Payment Reference Date \n Article Statement Code , Tax Code \n please input them together.");
                return false;
            }
            if (vatDtlDesc) {
                messageBarVM.showError("Tax Detail Desc. 1 / Tax Detail Desc. 2 \n please input them together.");
                return false;
            }
        }

        if (!currentCreditNoteDtl.taxCode) {
            messageBarVM.showError('Tax Code is mandatory.');
            return false;
        }
        if (currentCreditNoteDtl.vatPercent === null) {
            messageBarVM.showError('TAX % is mandatory.');
            return false;
        }
        if (!currentCreditNoteDtl.vatTariffType) {
            messageBarVM.showError('Tax Tariff Type is mandatory.');
            return false;
        }
        if (!currentCreditNoteDtl.vatTariffCode) {
            messageBarVM.showError('Tax Tariff Code is mandatory.');
            return false;
        }
        if (!currentCreditNoteDtl.vatArticleStatementCode) {
            messageBarVM.showError('Article Statement Code is mandatory.');
            return false;
        }
        if (!currentCreditNoteDtl.vatDateType) {
            messageBarVM.showError('Tax Payment Reference Date is mandatory.');
            return false;
        }
        if (!currentCreditNoteDtl.vatDays) {
            messageBarVM.showError('Tax Payment Term is mandatory.');
            return false;
        }
        return true;
    }, [currentCreditNoteHdr, selectedcreditNoteDetailRows, creditNoteDetails, isAdd, currentCreditNoteDtl, validateForRebateLine, checkTarCodeOrCOA, billToCpyCustomerCodeValidate, messageBarVM])

    const vatCoaAndCoaInfoValidate = useCallback(() => {
        if (currentCreditNoteDtl.tariffType && currentCreditNoteDtl.tariffCode) {
            if (!updateCoaOrValidCoa(false, true, true)) {
                return false;
            }
        }
        if (currentCreditNoteDtl.vatTariffType && currentCreditNoteDtl.vatTariffCode) {
            if (!updateTaxCoaOrValidTaxCoa(false, true, true, false)) {
                return false;
            }
        }
        return true;
    }, [currentCreditNoteDtl.tariffCode, currentCreditNoteDtl.tariffType, currentCreditNoteDtl.vatTariffCode, currentCreditNoteDtl.vatTariffType, updateCoaOrValidCoa, updateTaxCoaOrValidTaxCoa])

    const handleSaveClick = useCallback(async () => {
        setIsLoading(true);
        if (!vatCoaAndCoaInfoValidate() || !recordValidate()) {
            setIsLoading(false);
            return;
        }
        // if(!(!currentCreditNoteHdr.consortiumCode || currentCreditNoteHdr.voyageCode || currentCreditNoteHdr.vesselName)){
        //     const result = checkLineListedVoyage();
        // }
        creditNoteDetailVM.onTempSaveClick(currentCreditNoteDtl);
        // await creditNoteDetailVM.onSaveClick(currentCreditNoteHdr,currentCreditNoteDtl,isAdd)//;
        // .then((data) => {
        //     messageBarVM.showSuccess("Save successful.");
        setIsLoading(false);
        //     creditNoteDetailVM.onSearch(data);
        // });
    }, [creditNoteDetailVM, currentCreditNoteDtl, recordValidate, vatCoaAndCoaInfoValidate]);

    const handleUpdateTaxCOA = useCallback(() => {
        if (!currentCreditNoteDtl.vatTariffType) {
            messageBarVM.showError('Vat Tariff Type is mandatory.');
            return;
        }
        if (!currentCreditNoteDtl.vatTariffCode) {
            messageBarVM.showError('Vat Tariff Code is mandatory.');
            return;
        }
        updateTaxCoaOrValidTaxCoa(true, false, true, false);
    }, [currentCreditNoteDtl.vatTariffCode, currentCreditNoteDtl.vatTariffType, messageBarVM, updateTaxCoaOrValidTaxCoa]);

    const handleUpdateCOA = useCallback(() => {
        if (!currentCreditNoteDtl.tariffType) {
            messageBarVM.showError('Tariff Type is mandatory.');
            return;
        }
        if (!currentCreditNoteDtl.tariffCode) {
            messageBarVM.showError('Tariff Code is mandatory.');
            return;
        }
        updateCoaOrValidCoa(true, false, true);
    }, [currentCreditNoteDtl.tariffCode, currentCreditNoteDtl.tariffType, messageBarVM, updateCoaOrValidCoa]);

    const memoTariffCodeOptions = useMemo(() => {
        return currentCreditNoteDtl.tariffType
            ? creditNoteDetailState.dynamicOptions.tariffCodeDropdownOptions[currentCreditNoteDtl.tariffType]
            : [];
    }, [creditNoteDetailState.dynamicOptions.tariffCodeDropdownOptions, currentCreditNoteDtl.tariffType]);

    const memoTaxTariffCodeOptions = useMemo(() => {
        return currentCreditNoteDtl.vatTariffType
            ? creditNoteDetailState.dynamicOptions.taxTariffCodeDropdownOptions[currentCreditNoteDtl.vatTariffType]
            : [];
    }, [creditNoteDetailState.dynamicOptions.taxTariffCodeDropdownOptions, currentCreditNoteDtl.vatTariffType]);

    useEffect(() => {
        if (isDetailAddClick) {
            const refreshDefaultValue = async () => {
                // setInitScreen(true);

                await creditNoteDetailVM.refreshDefaultTaxCode(countryCode, companyCode, false, currentCreditNoteDtl);
                // setInitScreen(false);
            }
            if (!isRead && isDetailAddClick) {
                refreshDefaultValue();
            }
        }
    }, [companyCode, countryCode, currentCreditNoteDtl, creditNoteDetailVM, isDetailAddClick, isRead]);

    useEffect(() => {
        const refreshTariffDesc = async () => {

            creditNoteDetailVM.onTariffCodeItemChanged(false, countryCode, currentCreditNoteDtl/*.tariffType,currentCreditNoteDtl.tariffCode*/, 'tarDesc');
        }
        if (!isRead && currentCreditNoteDtl.tariffType && currentCreditNoteDtl.tariffCode) {
            refreshTariffDesc();
        }

    }, [currentCreditNoteDtl, creditNoteDetailVM, countryCode, isRead])


    useEffect(() => {
        const refreshVatTariffDesc = async () => {

            creditNoteDetailVM.onTariffCodeItemChanged(true, countryCode, currentCreditNoteDtl/*.vatTariffType,currentCreditNoteDtl.vatTariffCode*/, 'vatTariffCodeDesc');
        }
        if (!isRead && currentCreditNoteDtl.vatTariffType && currentCreditNoteDtl.vatTariffCode) {
            refreshVatTariffDesc();
        }
    }, [currentCreditNoteDtl, creditNoteDetailVM, countryCode, isRead])

    useEffect(() => {
        const refreshCreditNoteNatureOptions = async () => {
            if (currentCreditNoteDtl.adjType) {

                creditNoteDetailVM.onAdjTypeItemChanged(currentCreditNoteDtl.adjType);
            }
        }
        if (!isRead) {
            refreshCreditNoteNatureOptions();
        }
    }, [currentCreditNoteDtl.adjType, creditNoteDetailVM, isRead])

    const handleGetUom = () => {
        if (!checkTarCodeOrCOA(currentCreditNoteHdr, currentCreditNoteDtl)) {
            return;
        }
        if (!currentCreditNoteDtl.opsDate) {
            messageBarVM.showError('Please input the Ops Date.');
            return;
        }

        if (!currentCreditNoteDtl.tariffType || !currentCreditNoteDtl.tariffCode) {
            messageBarVM.showError('Please input the Tariff Code.');
            return;
        }

        creditNoteDetailVM.onGetUom(currentCreditNoteHdr.billToCompany,
            currentCreditNoteDtl.tariffType,
            currentCreditNoteDtl.tariffCode,
            currentCreditNoteDtl.opsDate,
            currentCreditNoteDtl.opsDate,
            currentCreditNoteHdr.operatingTml
        ).then((data) => {
            if (data && data["getUOMFail"]) {
                const err: string = data["getUOMFail"]?.toString() ?? "";
                messageBarVM.showError(err.replace("Error:", ""));
            }
        })
    }

    const onNumberFieldChange = useCallback((e: any, fieldName: string, fieldValue: any) => {
        creditNoteDetailVM.onFieldChange(fieldName, fieldValue);
    }, [creditNoteDetailVM]);

    const memoState = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.dtlState ?? ''}
                fieldValue={currentCreditNoteDtl?.dtlState}
                fieldLabel={CREDIT_NOTE_CONSTANT.STATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'dtlState'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.STATE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.dtlState, isSaveClicked])

    const memoInvoiceNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={currentCreditNoteDtl.invoiceNo !== '' || currentCreditNoteDtl.invoiceNo !== null}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.invoiceNo ?? ''}
                fieldValue={currentCreditNoteDtl?.invoiceNo}
                fieldLabel={CREDIT_NOTE_CONSTANT.INVOICE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'invoiceNo'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.INVOICE_NO, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.invoiceNo, isSaveClicked])

    const memoOriChargingQty = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={CREDIT_NOTE_CONSTANT.ORIG_CHARGING_QTY}
                maxLength={60}
                fieldName={"oriChargingQty"}
                value={currentCreditNoteDtl?.oriChargingQty ?? ''}
                errorMessage={allFormState?.oriChargingQty}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.oriChargingQty?.toString() ?? ''}
                fieldValue={currentCreditNoteDtl?.oriChargingQty}
                fieldLabel={CREDIT_NOTE_CONSTANT.ORIG_CHARGING_QTY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'oriChargingQty'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [CREDIT_NOTE_CONSTANT.ORIG_CHARGING_QTY, allFormState, currentCreditNoteDtl?.oriChargingQty, onNumberFieldChange])

    const memoOriAmount = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={CREDIT_NOTE_CONSTANT.ORIG_AMOUNT}
                maxLength={60}
                fieldName={"oriAmount"}
                value={currentCreditNoteDtl?.oriAmount ?? ''}
                errorMessage={allFormState?.oriAmount}
                disabled={true}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.oriAmount?.toString() ?? ''}
                fieldValue={currentCreditNoteDtl?.oriAmount}
                fieldLabel={CREDIT_NOTE_CONSTANT.ORIG_AMOUNT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'oriAmount'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [CREDIT_NOTE_CONSTANT.ORIG_AMOUNT, allFormState, currentCreditNoteDtl?.oriAmount, onNumberFieldChange])

    const memoCreditChargingQty = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={CREDIT_NOTE_CONSTANT.CREDIT_CHARGING_QTY}
                maxLength={60}
                fieldName={"creditChargingQty"}
                value={currentCreditNoteDtl?.creditChargingQty ?? ''}
                errorMessage={allFormState?.creditChargingQty}
                disabled={isRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.creditChargingQty?.toString() ?? ''}
                fieldValue={currentCreditNoteDtl?.creditChargingQty?.toString()}
                fieldLabel={CREDIT_NOTE_CONSTANT.CREDIT_CHARGING_QTY}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'creditChargingQty'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [CREDIT_NOTE_CONSTANT.CREDIT_CHARGING_QTY, allFormState, currentCreditNoteDtl?.creditChargingQty, isRead, onNumberFieldChange])

    const memoChargeQtyUom = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.individualChargeQtyUom ?? ''}
                fieldValue={currentCreditNoteDtl?.individualChargeQtyUom}
                fieldLabel={CREDIT_NOTE_CONSTANT.CHARGE_UOM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'individualChargeQtyUom'}
                options={uomDropdownOption}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.CHARGE_UOM, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.individualChargeQtyUom, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoCreditAmount = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={CREDIT_NOTE_CONSTANT.CREDIT_AMOUNT}
                maxLength={60}
                fieldName={"creditAmount"}
                value={currentCreditNoteDtl?.creditAmount ?? ''}
                errorMessage={allFormState?.creditAmount}
                disabled={isRead}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
            errorMessages={allFormState}
            isReadOnly={isRead}
            isShowOptional={true}
            readOnlyValue={currentCreditNoteDtl?.creditAmount?.toString() ?? ''}
            fieldValue={currentCreditNoteDtl?.creditAmount?.toString()}
            fieldLabel={CREDIT_NOTE_CONSTANT.CREDIT_AMOUNT}
            isSaveClicked={isSaveClicked}
            fieldType={FieldType.TEXT}
            fieldKey={'creditAmount'}
            maxLength={60}
            requiredFieldList={creditNoteDtlRequiredFieldList}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [CREDIT_NOTE_CONSTANT.CREDIT_AMOUNT, allFormState, currentCreditNoteDtl?.creditAmount, isRead, onNumberFieldChange])

    const memoRebateCheckBox = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <HPHCheckbox
                disabled={isRead || isByInvoice || isReadOnly}
                label={CREDIT_NOTE_CONSTANT.REBATE}
                size={'Small'}
                theme={'Secondary'}
                checked={creditNoteDetailState.creditNoteDetailState.currentCreditNoteDtl?.rebate ?? false}
                onChange={(e) => creditNoteDetailVM.onCheckboxChange(e.checked, "rebate")}
            />
        </div>
        , [CREDIT_NOTE_CONSTANT.REBATE, creditNoteDetailState.creditNoteDetailState.currentCreditNoteDtl?.rebate, creditNoteDetailVM, isByInvoice, isRead, isReadOnly])


    const memoTarType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.tariffType ?? ''}
                fieldValue={currentCreditNoteDtl?.tariffType}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAR_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffType'}
                options={creditNoteDetailState.dynamicOptions.tariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAR_TYPE, allFormState, creditNoteDetailState.dynamicOptions.tariffTypeDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.tariffType, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoTarCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.tariffCode ?? ''}
                fieldValue={currentCreditNoteDtl?.tariffCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAR_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'tariffCode'}
                options={memoTariffCodeOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAR_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.tariffCode, isByInvoice, isRead, isReadOnly, isSaveClicked, memoTariffCodeOptions])


    const memoTarDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.tarDesc ?? ''}
                fieldValue={currentCreditNoteDtl?.tarDesc}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAR_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'tarDesc'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAR_DESC, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.tarDesc, isSaveClicked])

    const memoDetailDesc1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "24px" }} >
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.dtlDesc1 ?? ''}
                fieldValue={currentCreditNoteDtl?.dtlDesc1}
                fieldLabel={CREDIT_NOTE_CONSTANT.DETAIL_DESC1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'dtlDesc1'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [CREDIT_NOTE_CONSTANT.DETAIL_DESC1, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.dtlDesc1, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoDetailDesc2 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "395px", marginBottom: "24px" }} >
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.dtlDesc2 ?? ''}
                fieldValue={currentCreditNoteDtl?.dtlDesc2}
                fieldLabel={CREDIT_NOTE_CONSTANT.DETAIL_DESC2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXTAREA}
                fieldKey={'dtlDesc2'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onTextAreaChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)}
            />
        </div>
        , [CREDIT_NOTE_CONSTANT.DETAIL_DESC2, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.dtlDesc2, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoOpsDate = useMemo(() =>
        <div className='im-flex-row-item'>
            <DatePickerComponent
                disabled={isRead || isByInvoice || isReadOnly}
                label={CREDIT_NOTE_CONSTANT.OPS_DATE}
                width="250px"
                date={currentCreditNoteDtl?.opsDate}
                fieldName="opsDate"
                errorMessage={allFormState ? allFormState["validateRangeDate"] : ""}
                onDateChange={creditNoteDetailVM.onFieldChange} />
        </div>
        , [isRead, isByInvoice, isReadOnly, CREDIT_NOTE_CONSTANT.OPS_DATE, currentCreditNoteDtl?.opsDate, allFormState, creditNoteDetailVM.onFieldChange])

    const memoAdjType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.adjType ?? ''}
                fieldValue={currentCreditNoteDtl?.adjType}
                fieldLabel={CREDIT_NOTE_CONSTANT.ADJ_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'adjType'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.ADJ_TYPE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.adjType, isSaveClicked])

    const memoCostCenterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.costCenterCode ?? ''}
                fieldValue={currentCreditNoteDtl?.costCenterCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.COST_CENTER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'costCenterCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.COST_CENTER_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.costCenterCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoProductServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.productServiceCode ?? ''}
                fieldValue={currentCreditNoteDtl?.productServiceCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.PRODUCT_SERVICE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'productServiceCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.PRODUCT_SERVICE_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.productServiceCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoSalesChannelCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.salesChannelCode ?? ''}
                fieldValue={currentCreditNoteDtl?.salesChannelCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.SALES_CHANNEL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'salesChannelCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.SALES_CHANNEL_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.salesChannelCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.countryCode || countryCode}
                fieldValue={currentCreditNoteDtl?.countryCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.COUNTRY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'countryCode'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.COUNTRY_CODE, allFormState, countryCode, creditNoteDetailVM, currentCreditNoteDtl?.countryCode, isSaveClicked])

    const memoIntercompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.intercompanyCode ?? ''}
                fieldValue={currentCreditNoteDtl?.intercompanyCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.INTER_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'intercompanyCode'}
                options={creditNoteDetailState.dynamicOptions.interCompanyCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.INTER_COMPANY_CODE, allFormState, creditNoteDetailState.dynamicOptions.interCompanyCodeDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.intercompanyCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoProjectCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.projectCode ?? ''}
                fieldValue={currentCreditNoteDtl?.projectCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.PROJECT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'projectCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.PROJECT_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.projectCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoSpareCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.spareCode ?? ''}
                fieldValue={currentCreditNoteDtl?.spareCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.SPARE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'spareCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.SPARE_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.spareCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.revenueCompanyCode || companyCode}
                fieldValue={currentCreditNoteDtl?.revenueCompanyCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.REVENUE_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'revenueCompanyCode'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.REVENUE_COMPANY_CODE, allFormState, companyCode, creditNoteDetailVM, currentCreditNoteDtl?.revenueCompanyCode, isSaveClicked])

    const memoRevenueAccountCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.revenueAccountCode ?? ''}
                fieldValue={currentCreditNoteDtl?.revenueAccountCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.REVENUE_AC_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'revenueAccountCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.REVENUE_AC_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.revenueAccountCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatPercent = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NumberInputComponent
                label={CREDIT_NOTE_CONSTANT.TAX_PENCENTAGE}
                maxLength={200}
                fieldName={"vatPercent"}
                value={currentCreditNoteDtl?.vatPercent ?? ''}
                errorMessage={allFormState?.vatPercent}
                disabled={isRead || isByInvoice || isReadOnly}
                decimalPlaces={2}
                onChange={(e: any, fieldKey: string, fieldValue: any) => onNumberFieldChange(e, fieldKey, fieldValue && fieldValue.toString())}
            />
            {/*<NbisCommonField
            errorMessages={allFormState}
            isReadOnly={isRead || isByInvoice || isReadOnly}
            isShowOptional={true}
            readOnlyValue={currentCreditNoteDtl?.vatPercent?.toString() ?? ''}
            fieldValue={currentCreditNoteDtl?.vatPercent?.toString()}
            fieldLabel={CREDIT_NOTE_CONSTANT.TAX_PENCENTAGE}
            isSaveClicked={isSaveClicked}
            fieldType={FieldType.TEXT}
            fieldKey={'vatPercent'}
            maxLength={200}
            requiredFieldList={creditNoteDtlRequiredFieldList}
            onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => 
                creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />*/}
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_PENCENTAGE, allFormState, currentCreditNoteDtl?.vatPercent, isByInvoice, isRead, isReadOnly, onNumberFieldChange])

    const memoVatRevenueACCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatRevenueACCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatRevenueACCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_REVENUE_AC_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatRevenueACCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_REVENUE_AC_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatRevenueACCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatCostCenterCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatCostCenterCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatCostCenterCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_COST_CENTER_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatCostCenterCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_COST_CENTER_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatCostCenterCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatProductServiceCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatProductServiceCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatProductServiceCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_PRODUCT_SERVICE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatProductServiceCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_PRODUCT_SERVICE_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatProductServiceCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatSalesChannelCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatSalesChannelCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatSalesChannelCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_SALES_CHANNEL_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatSalesChannelCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_SALES_CHANNEL_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatSalesChannelCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatCountryCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatCountryCode || countryCode}
                fieldValue={currentCreditNoteDtl?.vatCountryCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_COUNTRY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatCountryCode'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_COUNTRY_CODE, allFormState, countryCode, creditNoteDetailVM, currentCreditNoteDtl?.vatCountryCode, isSaveClicked])

    const memoVatIntercompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatIntercompanyCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatIntercompanyCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_INTER_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatIntercompanyCode'}
                options={creditNoteDetailState.dynamicOptions.taxInterCompanyCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_INTER_COMPANY_CODE, allFormState, creditNoteDetailState.dynamicOptions.taxInterCompanyCodeDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.vatIntercompanyCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatProjectCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatProjectCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatProjectCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_PROJECT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatProjectCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_PROJECT_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatProjectCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatSpareCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatSpareCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatSpareCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_SPARE_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatSpareCode'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_SPARE_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatSpareCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatCompanyCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatCompanyCode || companyCode}
                fieldValue={currentCreditNoteDtl?.vatCompanyCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_COMPANY_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatCompanyCode'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_COMPANY_CODE, allFormState, companyCode, creditNoteDetailVM, currentCreditNoteDtl?.vatCompanyCode, isSaveClicked])

    const memoVatTransactionType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatTransactionType ?? ''}
                fieldValue={currentCreditNoteDtl?.vatTransactionType}
                fieldLabel={CREDIT_NOTE_CONSTANT.TRANSACTION_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatTransactionType'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TRANSACTION_TYPE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatTransactionType, isSaveClicked])

    const memoVatDays = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatDays?.toString() ?? ''}
                fieldValue={currentCreditNoteDtl?.vatDays?.toString()}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_PAYMENT_TERM}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDays'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_PAYMENT_TERM, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatDays, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatArticleStatement = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatArticleStatement ?? ''}
                fieldValue={currentCreditNoteDtl?.vatArticleStatement}
                allValues={currentCreditNoteDtl?.vatArticleStatement ?? ''}
                fieldLabel={CREDIT_NOTE_CONSTANT.ARTICLE_STATEMENT}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatArticleStatement'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.ARTICLE_STATEMENT, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatArticleStatement, isSaveClicked])

    const memoVatDtlDesc1 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatDtlDesc1 ?? ''}
                fieldValue={currentCreditNoteDtl?.vatDtlDesc1}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_DETAIL_DESC1}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDtlDesc1'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_DETAIL_DESC1, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatDtlDesc1, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatDtlDesc2 = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatDtlDesc2 ?? ''}
                fieldValue={currentCreditNoteDtl?.vatDtlDesc2}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_DETAIL_DESC2}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatDtlDesc2'}
                maxLength={200}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_DETAIL_DESC2, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatDtlDesc2, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoTaxCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.taxCode ?? ''}
                fieldValue={currentCreditNoteDtl?.taxCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'taxCode'}
                options={creditNoteDetailState.dynamicOptions.taxCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_CODE, allFormState, creditNoteDetailState.dynamicOptions.taxCodeDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.taxCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatDateType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatDateType ?? ''}
                fieldValue={currentCreditNoteDtl?.vatDateType}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_PAYMENT_REFERENCE_DATE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatDateType'}
                options={vatPaymentReferenceDateDropdownOption}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_PAYMENT_REFERENCE_DATE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatDateType, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatTariffType = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatTariffType ?? ''}
                fieldValue={currentCreditNoteDtl?.vatTariffType}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_TARIFF_TYPE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatTariffType'}
                options={creditNoteDetailState.dynamicOptions.taxTariffTypeDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_TARIFF_TYPE, allFormState, creditNoteDetailState.dynamicOptions.taxTariffTypeDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.vatTariffType, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoVatTariffCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatTariffCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatTariffCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_TARIFF_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatTariffCode'}
                options={memoTaxTariffCodeOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_TARIFF_CODE, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatTariffCode, isByInvoice, isRead, isReadOnly, isSaveClicked, memoTaxTariffCodeOptions])

    const memoVatTariffCodeDesc = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatTariffCodeDesc ?? ''}
                fieldValue={currentCreditNoteDtl?.vatTariffCodeDesc}
                allValues={currentCreditNoteDtl?.vatTariffCodeDesc ?? ''}
                fieldLabel={CREDIT_NOTE_CONSTANT.TAX_TARIFF_CODE_DESC}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'vatTariffCodeDesc'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.TAX_TARIFF_CODE_DESC, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.vatTariffCodeDesc, isSaveClicked])

    const memoVatArticleStatementCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.vatArticleStatementCode ?? ''}
                fieldValue={currentCreditNoteDtl?.vatArticleStatementCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.ARTICLE_STATEMENT_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'vatArticleStatementCode'}
                options={creditNoteDetailState.dynamicOptions.articleStatementCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.ARTICLE_STATEMENT_CODE, allFormState, creditNoteDetailState.dynamicOptions.articleStatementCodeDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.vatArticleStatementCode, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoCreditNoteNo = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.creditNoteNo ?? ''}
                fieldValue={currentCreditNoteDtl?.creditNoteNo}
                fieldLabel={CREDIT_NOTE_CONSTANT.CREDIT_NOTE_NO}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'creditNoteNo'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.CREDIT_NOTE_NO, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.creditNoteNo, isSaveClicked])

    const memoChargeInd = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.chargeInd ?? ''}
                fieldValue={currentCreditNoteDtl?.chargeInd}
                fieldLabel={CREDIT_NOTE_CONSTANT.CHARGE_IND}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'chargeInd'}
                options={creditNoteDetailState.dynamicOptions.chargeIndDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.CHARGE_IND, allFormState, creditNoteDetailState.dynamicOptions.chargeIndDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.chargeInd, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoCreditNoteNature = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead || isByInvoice || isReadOnly}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.creditNoteNature ?? ''}
                fieldValue={currentCreditNoteDtl?.creditNoteNature}
                fieldLabel={CREDIT_NOTE_CONSTANT.CREDIT_NOTE_NATURE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'creditNoteNature'}
                options={creditNoteDetailState.dynamicOptions.creditNoteNatureDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.CREDIT_NOTE_NATURE, allFormState, creditNoteDetailState.dynamicOptions.creditNoteNatureDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.creditNoteNature, isByInvoice, isRead, isReadOnly, isSaveClicked])

    const memoExceptionMsg = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={true}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.exceptionMsg ?? ''}
                fieldValue={currentCreditNoteDtl?.exceptionMsg}
                allValues={currentCreditNoteDtl?.exceptionMsg ?? ''}
                fieldLabel={CREDIT_NOTE_CONSTANT.EXCEPTION_MSG}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.TEXT}
                fieldKey={'exceptionMsg'}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.EXCEPTION_MSG, allFormState, creditNoteDetailVM, currentCreditNoteDtl?.exceptionMsg, isSaveClicked])

    const memoReasonCode = useMemo(() =>
        <div className='im-flex-row-item' style={{ width: "250px", marginBottom: "24px" }}>
            <NbisCommonField
                errorMessages={allFormState}
                isReadOnly={isRead}
                isShowOptional={true}
                readOnlyValue={currentCreditNoteDtl?.reasonCode ?? ''}
                fieldValue={currentCreditNoteDtl?.reasonCode}
                fieldLabel={CREDIT_NOTE_CONSTANT.REASON_CODE}
                isSaveClicked={isSaveClicked}
                fieldType={FieldType.DROPDOWN}
                fieldKey={'reasonCode'}
                options={creditNoteDetailState.dynamicOptions.reasonCodeDropdownOptions}
                maxLength={60}
                requiredFieldList={creditNoteDtlRequiredFieldList}
                onFieldChange={(fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) =>
                    creditNoteDetailVM.onFieldChange(fieldKey, fieldValue && fieldValue.toString(), fFullValue)} />
        </div>
        , [CREDIT_NOTE_CONSTANT.REASON_CODE, allFormState, creditNoteDetailState.dynamicOptions.reasonCodeDropdownOptions, creditNoteDetailVM, currentCreditNoteDtl?.reasonCode, isRead, isSaveClicked])

    // const handleEdit = useCallback(() => {
    //     creditNoteDetailVM.onDetailEdit();
    // }, [creditNoteDetailVM]);

    return (
        <div className='side-form-content-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    {/* <Sidebarheader style={{width: '100%'}}>
                <SidebarTitle>{'Detail'}</SidebarTitle>
                    <SidebarActionCross>
                        {(!isAdd && (isEditable && isRead)) &&
                            <>
                                {
                                    (
                                        (isEditable)
                                    )
                                    && <IconButton fileName='Icon-pen' size='medium' toolTipText={'Edit'} toolTipArrow={false} onClick={handleEdit} />
                                }
                            </>}
                            <IconButton fileName="Icon-cross" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="left" toolTipText={'Close'} onClick={handleCancelClick} />
                        {((isAdd || (isEditable && !isRead))) &&
                            <>
                                {
                                    (
                                        (isAdd) ||
                                        (isEditable && !isRead)
                                    )
                                    && <IconButton fileName="Icon-tick" size="medium" disabled={false} toolTipArrow={false} toolTipPlacement="bottom" toolTipText={'Save'} onClick={() => handleSaveClick()} />
                                }
                            </>}
                    </SidebarActionCross>

                </Sidebarheader> */}

                    <Sidebarheader>
                        <SidebarTitle>{'Detail'}</SidebarTitle>
                    </Sidebarheader>
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Large" />}

            <div className={'add-edit-form'} style={{ height: '75vh', maxHeight: '75vh', overflow: 'auto' }}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        <CriteriaItemContainer>
                            {memoState}
                            {memoOriChargingQty}
                            {memoOriAmount}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {!isByManual && memoInvoiceNo}
                            {memoAdjType}
                            {memoCountryCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoCreditChargingQty}
                            {memoCreditAmount}
                            {memoRebateCheckBox}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoTarType}
                            {memoTarCode}
                            <HPHButton disabled={isRead || isByInvoice || isReadOnly} label={CREDIT_NOTE_CONSTANT.UPT_COA} size={'Small'} theme={'Secondary'} onClick={handleUpdateCOA} />
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoTarDesc}
                            {memoChargeQtyUom}
                            <HPHButton disabled={isRead || isByInvoice || isReadOnly} label={CREDIT_NOTE_CONSTANT.GET_UOM} size={'Small'} theme={'Secondary'} onClick={handleGetUom} />
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoDetailDesc1}
                            {memoDetailDesc2}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoOpsDate}
                            {memoChargeInd}
                            {memoCreditNoteNo}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoRevenueAccountCode}
                            {memoCostCenterCode}
                            {memoProductServiceCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSalesChannelCode}
                            {memoIntercompanyCode}
                            {memoProjectCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoSpareCode}
                            {memoCompanyCode}
                            {memoVatPercent}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVatRevenueACCode}
                            {memoVatCostCenterCode}
                            {memoVatProductServiceCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVatIntercompanyCode}
                            {memoVatSalesChannelCode}
                            {memoVatProjectCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVatSpareCode}
                            {memoVatArticleStatement}
                            {memoVatArticleStatementCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoCreditNoteNature}
                            {memoTaxCode}
                            {memoReasonCode}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVatTariffType}
                            {memoVatTariffCode}
                            <HPHButton disabled={isRead || isByInvoice || isReadOnly} label={CREDIT_NOTE_CONSTANT.UPT_TAX_COA} size={'Small'} theme={'Secondary'} onClick={handleUpdateTaxCOA} />
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVatTariffCodeDesc}
                            {memoVatDays}
                            {memoVatDateType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVatCountryCode}
                            {memoVatCompanyCode}
                            {memoVatTransactionType}
                        </CriteriaItemContainer>
                        <CriteriaItemContainer>
                            {memoVatDtlDesc1}
                            {memoVatDtlDesc2}
                            {memoExceptionMsg}
                        </CriteriaItemContainer>

                    </div>
                </div>
            </div>
            <div className='im-charge-data-search-criteria-buttons'>
                {
                    <>
                        <HPHButton label={!isRead?'Cancel':'Close'} size={'Small'} theme={'Secondary'} onClick={handleCancelClick} />
                        {!isRead && <HPHButton label={isAdd ? 'Add' : isEditable ? 'Update' : 'Save'} size={'Small'} theme={'Primary'} onClick={handleSaveClick} />}
                    </>
                }
            </div>
        </div>
    )
}