import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CreditNoteConstant } from "./CreditNoteConstant";

const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Invoice;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_CREDIT_NOTE_INVOICE_RECORD_COL_DEF: any[] = [
    // {
    //     headerName: CREDIT_NOTE_CONSTANT.PREVIEWREF_NO,
    //     field: 'id',
    //     enableRowGroup: false,
    //     enablePivot: true,
    //     enableValue: false,
    //     rowGroup: false,
    //     // pinned: "left",
    //     checkboxSelection: true,
    //     headerCheckboxSelection: true,
    //     filter: true,
    //     width: 200,
    // },
    {
        headerName: CREDIT_NOTE_CONSTANT.DOC_NO,
        field: 'docNo',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.DOC_TYPE,
        field: 'docType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.HANDLING_TML,
        field: 'handlingTerminal',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SERVICE_CODE,
        field: 'serviceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CUSTOMER_CODE,
        field: 'customerCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CUSTOMER_NAME1,
        field: 'customerName1',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CUSTOMER_NAME2,
        field: 'customerName2',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
        dataType: "date"
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CHARGE_TYPE,
        field: 'chargeType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CONSORTIUM_CODE,
        field: 'consortiumCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.VESSEL_CODE,
        field: 'vesselCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.VOYAGE_CODE,
        field: 'voyageCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.VESSEL_NAME,
        field: 'vesselName',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.DEP_DATE_TIME,
        field: 'etd',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "date"
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ERROR_MESSAGE,
        field: 'errorMsg',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.GENERATION_BY,
        field: 'createdBy',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.GENERATION_DATE,
        field: 'createdDateTime',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
        dataType: "date"
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.CONTRACT_CURRENCY,
        field: 'currencyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.BILLING_TYPE,
        field: 'billingType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.BILLING_CYCLE_CODE,
        field: 'billingCycleCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    // cellRenderers['docNo'] = cellRenderWithColorAndCircle;
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
}
);

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    // externalFnctions['docNo'] = calculateStateColor;
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}