import { SelectionChangedEvent } from "ag-grid-community";
import { CreditNoteInvoiceRecordEntity } from "domain/entity/CreditNote/CreditNoteInvoiceRecordEntity";
import { EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA } from "domain/entity/CreditNote/InvoiceRecordSearchCriteria";
import moment from "moment";
import { CreditNoteConstant } from "presentation/constant/CreditNote/CreditNoteConstant";
import { INITIAL_CREDIT_NOTE_INVOICE_RECORD_COL_DEF, transferRowData } from "presentation/constant/CreditNote/CreditNoteInvoiceRecordColumnDefinition";
import { useCreditNoteDetailVM } from "presentation/hook/CreditNote/useCreditNoteDetailVM";
import { useCreditNoteDetailTracked } from "presentation/store/CreditNote/CreditNoteDetailProvider";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { useCallback, useEffect, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";

export const GetRecordsByInvoiceNoTablePanel = () => {
  const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Invoice;
  const [creditNoteDetailState] = useCreditNoteDetailTracked();
  const creditNoteDetailVM = useCreditNoteDetailVM();
  // const {isShowTariffItemPanel} = creditNoteDetailState.creditNoteDetailState;
  const gridRef: any = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
  const { isSearchInvoice } = creditNoteDetailState.creditNoteDetailState;

  const handClose = useCallback(() => {
    creditNoteDetailVM.onInvoicePanelClose();
  }, [creditNoteDetailVM])

  const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
    const selectedRows = e.api.getSelectedRows();
    creditNoteDetailVM.updateSelectedInvoiceRecordCharges(selectedRows);
    setOnTableSelectionClicked(true);
  }, [creditNoteDetailVM])

  const handleRowClick = useCallback((data: CreditNoteInvoiceRecordEntity) => {
    creditNoteDetailVM.handleInvoiceRowClick(data);
  }, [creditNoteDetailVM])

  useEffect(() => {
    // const columnDefs = (INITIAL_CREDIT_NOTE_INVOICE_RECORD_COL_DEF?.slice());

    //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
    if (!onTableSelectionClicked) {
      gridRef.current?.gridRef.current.api?.deselectAll();
    }
  })

  useEffect(() => {
    const initialScreen = async () => {

      setIsLoading(true);
      try {
        let newSearchCriteria = { ...EMPTY_INVOCIE_RECORD_SEARCH_CRITERIA }
        newSearchCriteria = {
          ...newSearchCriteria,
          docDateFrom: moment().subtract(15, "days").startOf("day").toDate(),
          docDateTo: moment().endOf("day").toDate(),
        };
        const results = await Promise.allSettled([
          creditNoteDetailVM.getInvoiceRecords(newSearchCriteria),
        ]);
        results?.forEach((result, index) => {
          if (index === 0 && result.status === 'fulfilled') {

          }
        });
      } catch (error) {
      }
    }
    isSearchInvoice && initialScreen().then((data) => {
      setIsLoading(false);
    }).catch(error => {
      setIsLoading(false);
    });
  }, [creditNoteDetailVM, isSearchInvoice]);

  return <div className="side-form-content-wrapper" style={{ height: "41vh", maxHeight: "41vh", overflow: "auto" }}>
    {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
    {/* <div className="main-comp-wrapper"> */}
    <NbisTable
      id='invoice-records-table'
      isNewColumnSetting={true}
      columns={INITIAL_CREDIT_NOTE_INVOICE_RECORD_COL_DEF?.slice()}
      headerLabel={CREDIT_NOTE_CONSTANT.DOC_ITEM_LIST}
      headerActionButtons={[
        {
          id: 'onCloseButton',
          icon: 'Icon-cross',
          title: 'Close'
        }
      ]}
      onCloseButton={handClose}
      onRowClick={(e: any) => handleRowClick(e.data)}
      // onRowDoubleClick={(e:any) => tableDoubleClicked(e)}
      data={transferRowData(creditNoteDetailState.invoiceRecordList ?? [])}
      showPaginator={false}
      editable={false}
      showAddIcon={false}
      showDeleteButton={false}
      showReloadIcon={false}
      isScrollHighlighted={true}
      isRowHighligted={true}
      selectionMode={false}
      rowSelection={"single"}
      showActionButtons={true}
      handleSelectionChanged={handleSelectionChange}
      gridHeight="customHeight"
      customHeight="calc(30vh)"
      ref={gridRef}
    />
  </div>
}
