import type { CustomCellRendererProps } from "ag-grid-react";
import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { CreditNoteConstant } from "./CreditNoteConstant";

const CREDIT_NOTE_CONSTANT = CreditNoteConstant.Detail;
let dateFieldList: string[] = [];
let dateTimeFieldList: string[] = [];
export const INITIAL_CREDIT_NOTE_INVOICE_DTL_RECORD_COL_DEF: any[] = [
    {
        headerName: CREDIT_NOTE_CONSTANT.ADJ_TYPE,
        field: 'adjType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.DOC_TYPE,
        field: 'docType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ORIG_CHARGING_QTY,
        field: 'originalChargeQty',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.REF_CHARGE_AMOUNT,
        field: 'refChargeAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 230,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.ORIG_AMOUNT,
        field: 'origChargeAmount',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAR_TYPE,
        field: 'tariffType',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.TAR_CODE,
        field: 'tariffCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 120,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.REVENUE_AC_CODE,
        field: 'revenueAccountCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.COST_CENTER_CODE,
        field: 'costCenterCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.PRODUCT_SERVICE_CODE,
        field: 'productServiceCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 220,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SALES_CHANNEL_CODE,
        field: 'salesChannelCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.COUNTRY_CODE,
        field: 'countryCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.INTER_COMPANY_CODE,
        field: 'intercompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 200,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.PROJECT_CODE,
        field: 'projectCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.SPARE_CODE,
        field: 'spareCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 150,
    },
    {
        headerName: CREDIT_NOTE_CONSTANT.REVENUE_COMPANY_CODE,
        field: 'revenueCompanyCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: true,
        width: 180,
    },

]?.map((col, index) => {
    const cellRenderers: { [key: string]: ((params: CustomCellRendererProps) => {}) } = {};
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
}
);

export const transferRowData = (data: any[]) => {
    const externalFnctions: { [key: string]: ((fieldName: string, row: any) => {}) } = {};
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}